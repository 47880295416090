// React
import * as React from "react";

// MUI
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// Classes
import ExternalURL from "../classes/ExternalURL";
import MStyles from "../classes/MStyles";
import Page from "../classes/Page";

// Images
import Quarterly from "../img/quarterlytomarch2024.png";

export default class EmissionsHighlights extends React.Component {
  page = Page.EmissionsHighlights;

  render() {
    if (this.props.page !== this.page.page) return null;
    return (
      <div>
        <Typography variant="h1" sx={MStyles.h1_title} id="content">{this.page.title}</Typography>
        <Typography paragraph sx={MStyles.body}>
          The following is Australia&apos;s most recently published quarterly
          emissions data and projections insights.
        </Typography>
        <Typography variant="h2" sx={MStyles.h2}>
          Australia&apos;s quarterly emissions
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          The following chart shows the quarterly emissions from September 2004 to December 2023, including preliminary data to March 2024.
        </Typography>
        <Typography variant="h3" sx={MStyles.h3}>
          Net CO<sub>2</sub>-e emissions for Australia by quarter (seasonally adjusted and
          trend).
        </Typography>
        <div>
          <img
            src={Quarterly}
            width="100%"
            height="auto"
            title="Net CO2-e emissions for Australia by quarter (seasonally adjusted and trend)"
            alt="The chart showing the quarterly emissions from September 2004 to December 2023, including preliminary data to March 2024."
          />
          <Typography paragraph sx={MStyles.body}>
            Source: Department of Climate Change, Energy, the Environment and Water.
          </Typography>
        </div>
        <Typography paragraph sx={MStyles.body}>
          View the{" "}<Link href={ExternalURL.QUARTERLY.url} sx={MStyles.linkstyle} rel="noreferrer" alt={ExternalURL.QUARTERLY.alt}>
            {ExternalURL.QUARTERLY.text}
          </Link>.
        </Typography>

        <Typography variant="h2" sx={MStyles.h2}>
          Australia&apos;s emissions projections
        </Typography>
        <Typography paragraph sx={MStyles.body}>
          Visit{" "}
          <Link href={ExternalURL.PROJECTION.url} sx={MStyles.linkstyle} alt={ExternalURL.PROJECTION.alt}>
            {ExternalURL.PROJECTION.text}
          </Link>{" "}
          for the latest emissions projections report.
        </Typography>
      </div>
    );
  }
}
